body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.inputError {
  border: none !important;
  outline: 1px solid #BA1426
}

.label {
  height: 24px;
  margin-bottom: 8px;
  display: inline-block
}

.submit-button-error {
  font-size: 1em;
  margin: 12px 16px 
}
